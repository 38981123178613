<script>
    import { _, locale } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { slide } from 'svelte/transition'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import { getRecommendedProviders } from '$utils/common.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import QRCodeIcon from '$lib/icon/QRCodeIcon.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import LoginProviderGroup from '$lib/LoginProviderGroup.svelte'
    import ManagedProviderDropdown from '$lib/ManagedProviderDropdown.svelte'

    let {
        accountSelected = undefined,
        recommendedProviders = getRecommendedProviders(),
        continueWithQRCodeAjax = false,
        showManagedLogin = false,
        showQRLogin = false,
        contactLoginSuccess = () => {},
        handleContactError = () => {},
        continueWithEthereumExtension = () => {},
        continueWithProvider = () => {},
        handleManagedEmailSuccess = () => {},
        label = '',
        qrcode = () => {},
    } = $props()

    const dropdown = $state({
        managed: false,
        email: false,
        showAll: false,
    })
</script>

{#if label}
    <h1 data-test="page-heading" class="relative -ml-2 mb-2 font-medium">
        {label}
    </h1>
{/if}

<div data-test="above-fold-list" class="mb-2 space-y-2">
    {#each recommendedProviders as slug}
        {#if slug === 'email'}
            <Dropdown
                dataTest="email-btn"
                ariaLabel={$_('Continue with Email')}
                expanded={dropdown.email}
                id="email-container"
                onclick={() => (dropdown.email = !dropdown.email)}
            >
                <div
                    class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                >
                    <MailIcon />
                    <span class="block text-left" aria-hidden="true">
                        {$_('Continue with Email')}
                    </span>
                </div>
                {#if dropdown.email}
                    <div
                        class="px-4 pb-3 pt-1"
                        transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >
                        <VerifyEmail
                            login
                            recommendedProvider={true}
                            {accountSelected}
                            success={contactLoginSuccess}
                            error={handleContactError}
                        />
                    </div>
                {/if}
            </Dropdown>
        {:else if slug === 'ethereum'}
            {#if window.ethereum?.isMetaMask}
                <LoginProvider
                    login
                    provider={{ display: 'MetaMask', slug: 'metamask' }}
                    prefix="Continue with"
                    onclick={() =>
                        continueWithEthereumExtension({ accountSelected })}
                />
            {:else if window.ethereum}
                <LoginProvider
                    login
                    provider={{ display: 'Ethereum', slug: 'ethereum' }}
                    prefix="Continue with"
                    onclick={() =>
                        continueWithEthereumExtension({ accountSelected })}
                />
            {/if}
        {:else if !['qrcode', 'managed'].includes(slug)}
            <LoginProvider
                login
                provider={{ slug }}
                prefix="Continue with"
                onclick={(e) =>
                    continueWithProvider({
                        slug,
                        server: e,
                        body: { accountSelected },
                    })}
                ethereum={() =>
                    continueWithEthereumExtension({ accountSelected })}
            />
        {/if}
        {#if slug === 'qrcode' && showQRLogin && !global.isRemoteAuthClient}
            <button
                data-test="qr-btn"
                class="btn-background group relative flex h-12 w-full items-center justify-start px-4"
                onclick={qrcode}
                disabled={continueWithQRCodeAjax}
            >
                {#if continueWithQRCodeAjax}
                    <SpinnerIcon css="h-5.5 w-5.5 block mx-auto" />
                {:else}
                    <QRCodeIcon css="h-4.5 opacity-80" />

                    <span
                        class="{$locale && $locale.startsWith('ar')
                            ? 'mr-4'
                            : 'ml-4'} truncate"
                    >
                        {$_('Continue by scanning QR Code')}
                    </span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="group-hover:stroke-3 h-4.5 ml-auto transform stroke-2 text-white opacity-80 dark:text-[#d4d4d4]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                {/if}
            </button>
        {/if}
        {#if slug === 'managed' && showManagedLogin}
            <ManagedProviderDropdown
                login={true}
                hasBackground={true}
                {accountSelected}
                label={$_('Continue with Managed Provider')}
                bind:expanded={dropdown.managed}
                managedEmailSuccess={handleManagedEmailSuccess}
            />
        {/if}
    {/each}
</div>

<section
    data-test="below-fold-list"
    class="-mx-2 mt-2 rounded-md bg-[#DCDCDC] dark:bg-[#505050]"
>
    <button
        data-test="show-below-fold-providers-btn"
        onclick={() => (dropdown.showAll = !dropdown.showAll)}
        class="group flex h-full w-full items-center justify-between px-6 py-3 text-left"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="group-hover:stroke-3 group-focus:stroke-3 h-4.5 text-charcoal invisible transform stroke-2 opacity-80 dark:text-[#d4d4d4]"
            class:rotate-180={dropdown.showAll}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
            />
        </svg>
        <span
            >{!dropdown.showAll
                ? $_('Show other ways to log in')
                : $_('Hide other ways to log in')}</span
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="group-hover:stroke-3 group-focus:stroke-3 h-4.5 text-charcoal transform stroke-2 opacity-80 dark:text-[#d4d4d4]"
            class:rotate-180={dropdown.showAll}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
            />
        </svg></button
    >

    {#if dropdown.showAll}
        <div
            class="px-2 pb-3 pt-1"
            transition:slide={{ duration: SLIDE_ANIM_MS }}
        >
            <LoginProviderGroup
                login
                prefix="Continue with"
                {accountSelected}
                {continueWithQRCodeAjax}
                {showQRLogin}
                ethereum={() =>
                    continueWithEthereumExtension({ accountSelected })}
                {qrcode}
                ignoreProviders={recommendedProviders}
                cryptoWallets={!recommendedProviders.includes('ethereum')}
                managedEmailSuccess={handleManagedEmailSuccess}
                {showManagedLogin}
            >
                {#if !recommendedProviders.includes('email')}
                    <Dropdown
                        dataTest="email-btn"
                        ariaLabel={$_('Continue with Email')}
                        expanded={dropdown.email}
                        id="email-container"
                        onclick={() => (dropdown.email = !dropdown.email)}
                    >
                        <div
                            class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
                        >
                            <MailIcon />
                            <span class="block text-left" aria-hidden="true">
                                {$_('Continue with Email')}
                            </span>
                        </div>
                        {#if dropdown.email}
                            <div
                                class="px-4 pb-3 pt-1"
                                transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >
                                <VerifyEmail
                                    login
                                    success={contactLoginSuccess}
                                    error={handleContactError}
                                    {accountSelected}
                                />
                            </div>
                        {/if}
                    </Dropdown>
                {/if}
            </LoginProviderGroup>
        </div>
    {/if}
</section>
