<script>
    let { size = 'md' } = $props()
</script>

<svg
    xmlns="http://www.w3.org/2000/svg"
    class:w-4.5={size === 'md'}
    class:max-h-[18px]={size === 'md'}
    class:w-9={size === 'lg'}
    class:max-h-[36px]={size === 'lg'}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
>
    <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    />
</svg>
