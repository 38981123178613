<script>
    import { _, locale } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import ManagedProvider from '$lib/ManagedProvider.svelte'
    import HelpButton from '$lib/button/HelpButton.svelte'
    import LoginRequiredModal from '$lib/modal/LoginRequiredModal.svelte'

    let {
        heading = '',
        label = '',
        accountToUse = undefined,
        accountSelected = undefined,
        authnWithPreferred = false,
        authnWithManaged = false,
        accounts = [],
        showUseDifferentManaged = true,
        continueWithProvider = () => {},
        contactLoginSuccess = () => {},
        handleContactError = () => {},
        handleManagedEmailSuccess = () => {},
        continueWithEthereumExtension = () => {},
    } = $props()

    let useDifferentManaged = $state(false)
    let authnWithPreferredModal = $state(false)
    let authnWithManagedModal = $state(false)

    //auto-expand use different managed dropdown
    if (global.data?.useDifferentManaged) {
        useDifferentManaged = true
    }
</script>

<section data-test="managed-providers-container">
    {#if authnWithPreferredModal}
        <LoginRequiredModal
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {continueWithProvider}
            label={$_('To use a managed account')}
            providers={global.data?.preferred}
            accountToUse="managed"
            close={() => (authnWithPreferredModal = false)}
            useDifferentManaged={true}
        />
    {:else if authnWithManagedModal}
        <LoginRequiredModal
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {continueWithProvider}
            label={$_('To add a different managed account')}
            providers={global.data?.managed}
            accountToUse="managed"
            close={() => (authnWithManagedModal = false)}
            useDifferentManaged={true}
        />
    {/if}

    {#if heading}
        <h1
            class="relative mb-6 flex items-center justify-center text-lg font-medium"
        >
            {heading}
        </h1>
    {/if}

    {#if label}
        <h2
            class="block text-sm font-medium {$locale &&
            $locale.startsWith('ar')
                ? 'text-right'
                : 'text-left'}"
        >
            {@html label}
        </h2>
    {/if}

    <section class="mt-2">
        {#if Array.isArray(accounts)}
            <div class="space-y-2">
                {#each accounts as managed}
                    {#if managed.slug === 'email'}
                        <div
                            class="btn-border hover-none relative h-auto rounded-md px-4"
                        >
                            <button
                                class="flex h-16 w-full items-center justify-start"
                            >
                                <MailIcon size="lg" />
                                <div
                                    class="flex flex-col {$locale &&
                                    $locale.startsWith('ar')
                                        ? 'mr-4 text-right'
                                        : 'ml-4 text-left'}"
                                >
                                    <span>
                                        {$_('Continue with Email')}
                                    </span>
                                    <span>{managed.login_hint}</span>
                                </div>
                            </button>
                            <div class="pb-3 pt-1">
                                <VerifyEmail
                                    {accountToUse}
                                    {accountSelected}
                                    email={managed.login_hint}
                                    login
                                    disabled
                                    success={contactLoginSuccess}
                                    error={handleContactError}
                                />
                            </div>
                        </div>
                    {:else}
                        <LoginProvider
                            login={true}
                            managed={true}
                            onclick={() =>
                                continueWithProvider({
                                    slug: managed.slug,
                                    body: {
                                        login_hint: managed.login_hint,
                                        accountToUse,
                                        accountSelected,
                                    },
                                    server:
                                        managed.slug === 'mastodon'
                                            ? managed.mastodonServer
                                            : null,
                                })}
                            provider={managed}
                            prefix="Continue with"
                        />
                    {/if}
                {/each}
            </div>
        {/if}

        {#if showUseDifferentManaged}
            <div class="my-2 flex items-center justify-between">
                <button
                    onclick={() => {
                        if (authnWithPreferred) {
                            authnWithPreferredModal = true
                        } else if (authnWithManaged) {
                            authnWithManagedModal = true
                        } else {
                            useDifferentManaged = !useDifferentManaged
                        }
                    }}
                    class="inline-flex items-center space-x-1 text-sm hover:underline focus:underline {$locale &&
                    $locale.startsWith('ar')
                        ? 'text-left'
                        : 'text-right'}"
                >
                    {#if accounts?.length}
                        {useDifferentManaged
                            ? $_('Hide other managed account')
                            : $_('Use a different managed account')}
                    {:else}
                        <span
                            >{useDifferentManaged
                                ? $_('Hide other managed account')
                                : $_('Use a managed account')}</span
                        >
                        <HelpButton
                            ariaLabel="Help"
                            content={$_(
                                'Accounts managed by your employer, school, or other organization',
                            )}
                            placement="bottom"
                        />
                    {/if}
                </button>
            </div>
        {/if}
    </section>

    {#if useDifferentManaged}
        <ManagedProvider
            login={true}
            {accountSelected}
            css="mt-3"
            managedEmailSuccess={handleManagedEmailSuccess}
        />
    {/if}
</section>
