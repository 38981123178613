const mastodonCache = {
    'c.im': {},
    'counter.social': {},
    'fosstodon.org': {},
    'gc2.jp': {},
    'home.social': {},
    'mas.to': {},
    'masto.ai': {},
    'mastodon.cloud': {},
    'mastodon.hello-dev.net': {},
    'mastodon.lol': {},
    'mastodon.online': {},
    'mastodon.social': {},
    'mastodon.uno': {},
    'mastodon.world': {},
    'mastodonapp.uk': {},
    'mstdn.jp': {},
    'mstdn.social': {},
    'pawoo.net': {},
    'pixelfed.social': {},
    'social.coop': {},
    'techhub.social': {},
    'universeodon.com': {},
}

export { mastodonCache }
