<script>
    import { slide } from 'svelte/transition'
    import { _ } from 'svelte-i18n'
    import { trimEthAddress } from '$utils/ethereum.js'
    import { SLIDE_ANIM_MS } from '$src/constants'

    let { notifications = [], cancel = () => {}, ok = () => {} } = $props()
</script>

<div
    class="fixed inset-0 z-[200] overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
>
    <div
        class="block flex min-h-screen items-center justify-center p-0 px-4 pb-20 pt-4 text-center"
    >
        <div
            onclick={cancel}
            class="bg-charcoal fixed inset-0 bg-opacity-80 transition-opacity"
            aria-hidden="true"
        ></div>
        <span
            class="inline-block hidden h-screen align-middle"
            aria-hidden="true">&#8203;</span
        >
        <div
            class="modal relative my-8 inline-block w-full max-w-sm transform overflow-hidden rounded-lg text-left align-middle align-bottom shadow-xl transition-all"
        >
            <ul class="space-y-0.5">
                {#each notifications as notification}
                    {#if notification.text}
                        <li
                            class="{notification.type === 'error'
                                ? 'bg-red-500'
                                : 'bg-green-500'} text-charcoal py-1.5 text-center text-sm"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            {notification.text}
                        </li>
                    {/if}
                {/each}
            </ul>
            <div
                class="flex h-36 items-center justify-center px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
            >
                {#if notifications[notifications.length - 1].status.startsWith('0x')}
                    <div class="w-full text-center">
                        {$_('Sign with {address}', {
                            values: {
                                address: trimEthAddress(
                                    notifications[notifications.length - 1]
                                        .status,
                                ),
                            },
                        })}

                        <div
                            class="mx-auto mt-6 flex w-56 w-full items-center justify-between"
                        >
                            <button
                                onclick={cancel}
                                class="btn-border h-8 w-20 text-sm"
                            >
                                {$_('Cancel')}
                            </button>

                            <button
                                onclick={() =>
                                    ok(
                                        notifications[notifications.length - 1]
                                            .status,
                                    )}
                                class="btn-border h-8 w-20 text-sm"
                            >
                                {$_('OK')}
                            </button>
                        </div>
                    </div>
                {:else}
                    <div class="text-charcoal text-center dark:text-[#D4D4D4]">
                        <svg
                            id="spinner"
                            style="height: 2.4rem; width: 2.4rem"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="mx-auto"
                        >
                            <circle
                                style="opacity: 0.5"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="3"
                            />
                            <path
                                opacity="0.5"
                                d="M2 12C2 6.47715 6.47715 2 12 2"
                                stroke="black"
                                stroke-width="3"
                            />
                        </svg>
                        <span class="mt-5 block">
                            {notifications[notifications.length - 1].status}
                        </span>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
