<script>
    import { _, locale } from 'svelte-i18n'
    import logins from '$svr/providers/logins.json'
    import { slide } from 'svelte/transition'
    import {
        getDomainDiscovery,
        postLinkProvider,
        postLoginProvider,
    } from '$utils/api.js'
    import { isValidEmail } from '$utils/email'
    import { global } from '$src/state.svelte.js'
    import { SLIDE_ANIM_MS, DISCO_DEBOUNCE_MS } from '$src/constants.js'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'

    let {
        login = false,
        css = '',
        accountSelected = undefined,
        managedEmailSuccess = () => {},
    } = $props()

    let value = $state('')
    let showManagedNotFoundErrorText = $state(false)
    let loginProviderFound = $state(false)
    let managedProviderFound = $state(false)
    let checkManagedAjax = $state(false)
    let isPublicEmailDomain = $state(false)
    let discoRes = $state()

    const domain = $derived(value?.split('@')[1])

    let timer
    async function handleInput() {
        //tbd cleanup
        checkManagedAjax =
            showManagedNotFoundErrorText =
            isPublicEmailDomain =
                false
        loginProviderFound = false
        managedProviderFound = false
        discoRes = null
        const email = value
        const domain = email?.split('@')[1]

        if (timer) clearTimeout(timer)

        if (!isValidEmail(value)) return

        timer = setTimeout(async () => {
            if (!email.length) return
            checkManagedAjax = true
            discoRes = await getDomainDiscovery(domain)
            if (email !== value) return
            if (discoRes.public) {
                isPublicEmailDomain = true
            } else if (
                discoRes.slug &&
                discoRes.slug !== 'unknown' &&
                !discoRes.managed
            ) {
                loginProviderFound = true
            } else if (
                discoRes.slug &&
                discoRes.slug !== 'unknown' &&
                discoRes.managed
            ) {
                managedProviderFound = true
            } else {
                showManagedNotFoundErrorText = true
            }
            checkManagedAjax = false
        }, DISCO_DEBOUNCE_MS)
    }

    async function continueWithProvider(slug) {
        global.spinner = true
        const body = {
            accountSelected,
            // accountToUse: 'managed',
            managedRequest: true,
            login_hint: value || undefined, //dont send empty login_hint
        }
        const { redirect } = login
            ? await postLoginProvider({ slug, body })
            : await postLinkProvider({ slug, body })
        window.location.href = redirect
    }
</script>

<div class="w-full {css}" data-test="managed-provider">
    <!-- svelte-ignore a11y_autofocus -->
    <input
        autofocus
        type="text"
        name="managed-email"
        bind:value
        oninput={handleInput}
        id="managed-email"
        autocapitalize="off"
        placeholder="enter your managed email address"
        class="h-12 w-full bg-transparent px-[16px] sm:px-[18px]"
    />
    {#if !managedProviderFound && !loginProviderFound}
        <div class="flex h-16 items-center justify-center">
            {#if showManagedNotFoundErrorText || isPublicEmailDomain}
                <span
                    class=" mt-2 block text-center"
                    data-test="managed-input-error"
                >
                    <span class="font-semibold">{domain}</span><br />
                    {#if showManagedNotFoundErrorText}
                        <span class="text-red-500"
                            >{$_('could not find provider')}</span
                        >
                    {:else if isPublicEmailDomain}
                        <span class="text-red-500"
                            >{$_('is a public email domain')}</span
                        >
                        <!-- <span class="text-red-500">{$_('not from a managed provider')}</span> -->
                    {/if}
                </span>
            {:else if checkManagedAjax}
                <SpinnerIcon css="w-5 h-5" />
            {:else}
                <span class="my-4 block uppercase">{$_('Or')}</span>
            {/if}
        </div>
    {/if}

    {#if loginProviderFound}
        <div
            class="mt-3 flex flex-col items-center justify-center"
            transition:slide={{ duration: SLIDE_ANIM_MS }}
        >
            <LoginProvider
                onclick={() => continueWithProvider(discoRes.slug)}
                prefix="Continue with"
                provider={{
                    slug: discoRes.slug,
                    user_name: value,
                }}
            />
            <span class="mx-auto my-4 block uppercase">{$_('Or')}</span>
            <div
                data-test="verify-email-managed"
                class="btn-border hover-none relative h-auto w-full rounded-md px-4 pt-4"
            >
                <button class="mb-2 flex w-full items-center justify-start">
                    <MailIcon size="lg" />
                    <div
                        class="flex flex-col {$locale &&
                        $locale.startsWith('ar')
                            ? 'mr-4 text-right'
                            : 'ml-4 text-left'}"
                    >
                        <span>
                            {$_('Continue with Email')}
                        </span>
                        <span>{value}</span>
                    </div>
                </button>

                <div class="pb-4 pt-1">
                    <VerifyEmail
                        {login}
                        email={value}
                        showEmail={false}
                        disabled
                        accountToUse="managed"
                        success={managedEmailSuccess}
                    />
                </div>
            </div>
        </div>
    {:else if !isPublicEmailDomain}
        <div
            class="flex flex-col items-start"
            class:mt-1={managedProviderFound}
            transition:slide={{ duration: SLIDE_ANIM_MS }}
        >
            {#if !managedProviderFound}
                <span>{$_('Select a provider')}</span>
            {/if}

            <div class="mt-2 w-full">
                {#if managedProviderFound}
                    <div>
                        <LoginProvider
                            onclick={() => continueWithProvider(discoRes.slug)}
                            logo={discoRes.logo}
                            prefix="Continue with"
                            managed={true}
                            provider={{
                                slug: discoRes.logo ? '' : discoRes.slug,
                                user_name: value,
                            }}
                        />
                    </div>
                {:else}
                    <div class="space-y-2">
                        {#each logins.filter((i) => i.managed) as provider}
                            <LoginProvider
                                onclick={() =>
                                    continueWithProvider(provider.slug)}
                                disabled={checkManagedAjax}
                                prefix="Continue with"
                                {provider}
                            />
                        {/each}

                        {#if showManagedNotFoundErrorText}
                            <div
                                data-test="verify-email-managed"
                                class="btn-border hover-none relative h-auto rounded-md px-4 pt-4"
                            >
                                <button
                                    class="mb-2 flex w-full items-center justify-start"
                                >
                                    <MailIcon size="lg" />
                                    <div
                                        class="flex flex-col {$locale &&
                                        $locale.startsWith('ar')
                                            ? 'mr-4 text-right'
                                            : 'ml-4 text-left'}"
                                    >
                                        <span>
                                            {$_('Continue with Email')}
                                        </span>
                                        <span>{value}</span>
                                    </div>
                                </button>

                                <div class="pb-4 pt-1">
                                    <VerifyEmail
                                        {login}
                                        email={value}
                                        disabled
                                        accountToUse="managed"
                                        success={managedEmailSuccess}
                                    />
                                </div>
                            </div>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
    {/if}
</div>
