<script>
    import tippy from 'sveltejs-tippy'
    import { _, locale } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { getDisplay } from '$utils/common'
    import { DEFAULT_MANAGED_LOGO } from '$src/constants.js'
    import PhoneIcon from '$lib/icon/PhoneIcon.svelte'
    import LoggedInSubject from './LoggedInSubject.svelte'
    import HelpButton from '$lib/button/HelpButton.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import VerifyPhone from '$lib/VerifyPhone.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'

    let {
        continueWithProvider = () => {},
        continueWithEthereumExtension = () => {},
        contactLoginSuccess = () => {},
        handleContactError = () => {},
        loggedInSubject = {},
        accountSelected = undefined,
    } = $props()
</script>

{#if !loggedInSubject.managed}
    <h1
        class="relative flex items-center justify-center text-center text-lg font-medium"
    >
        <span class="w-3/5">
            {#if global.data.recovery?.length > 1}
                {$_('You logged in with one of your recovery providers')}
            {:else}
                {$_('You logged in with your only recovery provider')}
            {/if}
        </span>
        <span class="absolute right-0 inline-flex items-center">
            <HelpButton
                ariaLabel="Help"
                content={$_(
                    'You use recovery providers if you lose access to your preferred provider',
                )}
                placement="top"
            />
        </span>
    </h1>
{/if}

{#if loggedInSubject.managed}
    <LoggedInSubject
        heading={$_('You are logged in with your managed account')}
        logo={loggedInSubject.logo || DEFAULT_MANAGED_LOGO}
        userName={loggedInSubject.user_name}
    />
{:else}
    <section
        data-test="loggedin-provider-container"
        class="-mx-2 mt-2 rounded-md bg-[#DCDCDC] p-2 dark:bg-[#505050]"
    >
        <span
            class="{$locale && $locale.startsWith('ar')
                ? 'text-right'
                : 'text-left'} block text-sm font-medium"
            >{$_('Logged in recovery provider')}</span
        >
        <div
            class="text-charcoal mt-2 flex flex-col items-start justify-center rounded-md px-4 dark:text-[#D4D4D4]"
        >
            <div class="inline-flex items-center gap-x-4">
                <span
                    class="inline-flex items-center justify-center rounded-sm"
                >
                    {#if loggedInSubject.slug === 'ethereum' && loggedInSubject.wallet}
                        <img
                            src={loggedInSubject.wallet.icon}
                            alt="{loggedInSubject.wallet.name} logo"
                            class="max-h-[36px] w-9"
                        />
                    {:else}
                        <img
                            src="https://cdn.hello.coop/images/{loggedInSubject.slug}.svg"
                            alt="{loggedInSubject.slug} logo"
                            class="max-h-[36px] w-9 {[
                                'apple',
                                'twitter',
                                'email',
                                'phone',
                            ].includes(
                                //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
                                loggedInSubject.slug,
                            )
                                ? 'dark:invert-20 invert'
                                : ''}"
                        />
                    {/if}
                </span>
                <div
                    class="{$locale && $locale.startsWith('ar')
                        ? 'text-right'
                        : 'text-left'} flex flex-col"
                >
                    <span>
                        {#if loggedInSubject.slug === 'ethereum'}
                            {#if loggedInSubject.wallet}
                                {loggedInSubject.wallet.name}
                            {:else}
                                Ethereum
                            {/if}
                        {:else if loggedInSubject.slug === 'email'}
                            {$_('Email')}
                        {:else if loggedInSubject.slug === 'phone'}
                            {$_('Phone')}
                        {:else}
                            {getDisplay(loggedInSubject.slug)}
                        {/if}
                        ({$_('Recovery Provider')})
                    </span>
                    <span class="mt-1">
                        {#if loggedInSubject.user_name}
                            {loggedInSubject.user_name}
                        {:else if loggedInSubject.login_hint}
                            {loggedInSubject.login_hint}
                        {/if}
                    </span>
                </div>
            </div>
        </div>
    </section>
{/if}

{#if !loggedInSubject.managed && loggedInSubject && global.data.recovery?.length === 1 && global.data.preferred?.length}
    <h1
        class="relative mt-6 flex items-center justify-center text-lg font-medium"
    >
        <span class="w-3/4">
            {$_(
                'You must have two or more recovery providers to recover your wallet',
            )}
        </span>

        <span class="absolute right-0 inline-flex items-center">
            <HelpButton
                ariaLabel="Help"
                content={$_(
                    'You can add recovery providers at wallet.hello.coop',
                )}
                placement="top"
            />
        </span>
    </h1>
    <h1
        class="relative mt-6 flex items-center justify-center text-lg font-medium"
    >
        <span class="w-3/5 text-center"
            >{$_('You can only log in with your preferred provider')}</span
        >
        <span class="absolute right-0 inline-flex items-center">
            <HelpButton
                ariaLabel="Help"
                content={$_(
                    'This is your preferred way to log in. You can change this at wallet.hello.coop',
                )}
                placement="top"
            />
        </span>
    </h1>
    <section class="mt-4 space-y-2">
        {#each global.data.preferred as preferred}
            {#if preferred.slug === 'email'}
                <div
                    class="btn-border hover-none relative h-auto rounded-md px-4"
                >
                    <button class="flex h-16 w-full items-center justify-start">
                        <MailIcon size="lg" />
                        <div
                            class="flex flex-col {$locale &&
                            $locale.startsWith('ar')
                                ? 'mr-4 text-right'
                                : 'ml-4 text-left'}"
                        >
                            <span>
                                {$_('Continue with Email')}
                            </span>
                            <span>{preferred.login_hint}</span>
                        </div>
                    </button>
                    <div class="pb-3 pt-1">
                        <VerifyEmail
                            {accountSelected}
                            email={preferred.login_hint}
                            login
                            disabled
                            success={contactLoginSuccess}
                            error={handleContactError}
                        />
                    </div>
                </div>
            {:else if preferred.slug === 'phone'}
                <div
                    class="btn-border hover-none relative h-auto rounded-md px-4"
                >
                    <button class="flex h-16 w-full items-center justify-start">
                        <PhoneIcon size="lg" />
                        <div
                            class="flex flex-col {$locale &&
                            $locale.startsWith('ar')
                                ? 'mr-4 text-right'
                                : 'ml-4 text-left'}"
                        >
                            <span>
                                {$_('Continue with Phone')}
                            </span>
                            <span>{preferred.login_hint}</span>
                        </div>
                    </button>
                    <div class="pb-3 pt-1">
                        <VerifyPhone
                            {accountSelected}
                            phone={preferred.login_hint}
                            login
                            disabled
                            success={contactLoginSuccess}
                            error={handleContactError}
                        />
                    </div>
                </div>
            {:else if preferred.slug === 'ethereum'}
                <div
                    class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2"
                >
                    <span class="mb-3 block text-left"
                        >{preferred.wallet?.name || 'Ethereum'}
                        {#if preferred.wallet?.display}
                            <span
                                use:tippy={{
                                    content: preferred.login_hint,
                                    placement: 'top',
                                }}
                            >
                                ({preferred.wallet?.display})
                            </span>
                        {/if}
                    </span>
                    {#if preferred.slug === 'ethereum' && window.ethereum}
                        <LoginProvider
                            ethereum={() =>
                                continueWithEthereumExtension({
                                    info: preferred,
                                    accountSelected,
                                })}
                            provider={preferred}
                            hideusername
                            prefix="Continue with"
                        />
                    {/if}
                </div>
            {:else}
                <LoginProvider
                    login={true}
                    onclick={() =>
                        continueWithProvider({
                            slug: preferred.slug,
                            body: {
                                login_hint: preferred.login_hint,
                                accountSelected,
                            },
                            server:
                                preferred.slug === 'mastodon'
                                    ? preferred.mastodonServer
                                    : null,
                        })}
                    provider={preferred}
                    prefix="Continue with"
                />
            {/if}
        {/each}
    </section>
{:else if global.data?.recovery?.length >= 2 && global.data?.preferred?.length}
    <section class="mt-6 text-center">
        <h1
            class="relative mb-4 flex items-center justify-center text-lg font-medium"
        >
            {$_('Log in with your personal account')}
            <span class="absolute right-0 inline-flex items-center">
                <HelpButton
                    ariaLabel="Help"
                    content={$_(
                        'This is your preferred way to log in. You can change this at wallet.hello.coop',
                    )}
                    placement="top"
                />
            </span>
        </h1>
        <div class="space-y-2">
            {#each global.data.preferred as preferred}
                {#if preferred.slug === 'email'}
                    <div
                        class="btn-border hover-none relative h-auto rounded-md px-4"
                    >
                        <button
                            class="flex h-16 w-full items-center justify-start"
                        >
                            <MailIcon size="lg" />
                            <div
                                class="flex flex-col {$locale &&
                                $locale.startsWith('ar')
                                    ? 'mr-4 text-right'
                                    : 'ml-4 text-left'}"
                            >
                                <span>
                                    {$_('Continue with Email')}
                                </span>
                                <span>{preferred.login_hint}</span>
                            </div>
                        </button>
                        <div class="pb-3 pt-1">
                            <VerifyEmail
                                {accountSelected}
                                email={preferred.login_hint}
                                login
                                disabled
                                success={contactLoginSuccess}
                                error={handleContactError}
                            />
                        </div>
                    </div>
                {:else if preferred.slug === 'phone'}
                    <div
                        class="btn-border hover-none relative h-auto rounded-md px-4"
                    >
                        <button
                            class="flex h-16 w-full items-center justify-start"
                        >
                            <PhoneIcon size="lg" />
                            <div
                                class="flex flex-col {$locale &&
                                $locale.startsWith('ar')
                                    ? 'mr-4 text-right'
                                    : 'ml-4 text-left'}"
                            >
                                <span>
                                    {$_('Continue with Phone')}
                                </span>
                                <span>{preferred.login_hint}</span>
                            </div>
                        </button>
                        <div class="pb-3 pt-1">
                            <VerifyPhone
                                {accountSelected}
                                phone={preferred.login_hint}
                                login
                                disabled
                                success={contactLoginSuccess}
                                error={handleContactError}
                            />
                        </div>
                    </div>
                {:else if preferred.slug === 'ethereum'}
                    <div
                        class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2"
                    >
                        <span class="mb-3 block text-left"
                            >{preferred.wallet?.name || 'Ethereum'}
                            {#if preferred.wallet?.display}
                                <span
                                    use:tippy={{
                                        content: preferred.login_hint,
                                        placement: 'top',
                                    }}
                                >
                                    ({preferred.wallet?.display})
                                </span>
                            {/if}
                        </span>
                        {#if preferred.slug === 'ethereum' && window.ethereum}
                            <LoginProvider
                                ethereum={() =>
                                    continueWithEthereumExtension({
                                        info: preferred,
                                        accountSelected,
                                    })}
                                provider={preferred}
                                hideusername
                                prefix="Continue with"
                            />
                        {/if}
                    </div>
                {:else}
                    <LoginProvider
                        login={true}
                        onclick={() =>
                            continueWithProvider({
                                slug: preferred.slug,
                                body: {
                                    login_hint: preferred.login_hint,
                                    accountSelected,
                                },
                                server:
                                    preferred.slug === 'mastodon'
                                        ? preferred.mastodonServer
                                        : null,
                                preferred: true,
                            })}
                        provider={preferred}
                        prefix="Continue with"
                    />
                {/if}
            {/each}
        </div>
        <h1 class="mb-4 mt-6 block text-xl font-medium uppercase">
            {$_('Or')}
        </h1>
    </section>
    <section
        data-test="recovery-providers-container"
        class="-mx-2 mt-3 rounded-md bg-[#DCDCDC] p-2 dark:bg-[#505050]"
    >
        <h2
            class="relative mb-4 flex items-center justify-center text-center text-lg font-medium"
        >
            <span class="w-3/4">
                {#if global.data.recovery?.filter((i) => i.id !== loggedInSubject.id).length === 1}
                    {$_(
                        'Complete logging in with your second recovery provider',
                    )}
                {:else}
                    {$_('Complete logging in with a second recovery provider')}
                {/if}
            </span>

            <span class="absolute right-0 inline-flex items-center">
                <HelpButton
                    ariaLabel="Help"
                    content={$_(
                        'You need to log in with two recovery providers in order to access your wallet',
                    )}
                    placement="top"
                />
            </span>
        </h2>
        <div class="space-y-2">
            <!-- Filter out logged in subject -->
            {#each global.data.recovery?.filter((i) => i.id !== loggedInSubject.id) as recovery}
                {#if recovery.slug === 'email'}
                    <div
                        class="btn-border hover-none relative h-auto rounded-md px-4"
                    >
                        <button
                            class="flex h-16 w-full items-center justify-start"
                        >
                            <MailIcon size="lg" />
                            <div
                                class="flex flex-col {$locale &&
                                $locale.startsWith('ar')
                                    ? 'mr-4 text-right'
                                    : 'ml-4 text-left'}"
                            >
                                <span
                                    >{$_('Continue with {provider}', {
                                        values: {
                                            provider: $_('Email'),
                                        },
                                    })}</span
                                >
                                <span>{recovery.login_hint}</span>
                            </div>
                        </button>
                        <div class="pb-4 pt-1">
                            <VerifyEmail
                                accountToUse="personal"
                                {accountSelected}
                                email={recovery.login_hint}
                                login
                                disabled
                                success={contactLoginSuccess}
                                error={handleContactError}
                            />
                        </div>
                    </div>
                {:else if recovery.slug === 'phone'}
                    <div
                        class="btn-border hover-none relative h-auto rounded-md px-4"
                    >
                        <button
                            class="flex h-16 w-full items-center justify-start"
                        >
                            <PhoneIcon size="lg" />
                            <div
                                class="flex flex-col {$locale &&
                                $locale.startsWith('ar')
                                    ? 'mr-4 text-right'
                                    : 'ml-4 text-left'}"
                            >
                                <span>
                                    {$_('Continue with Phone')}
                                </span>
                                <span>{recovery.login_hint}</span>
                            </div>
                        </button>
                        <div class="pb-4 pt-1">
                            <VerifyPhone
                                phone={recovery.login_hint}
                                accountToUse="personal"
                                {accountSelected}
                                login
                                disabled
                                success={contactLoginSuccess}
                                error={handleContactError}
                            />
                        </div>
                    </div>
                {:else if recovery.slug === 'ethereum'}
                    {#if window.ethereum}
                        <div
                            class="btn-border hover-none relative h-auto space-y-2 rounded-md p-2"
                        >
                            <span class="mb-3 block text-left"
                                >{recovery.wallet?.name || 'Ethereum'}
                                {#if recovery.wallet?.display}
                                    <span
                                        use:tippy={{
                                            content: recovery.login_hint,
                                            placement: 'top',
                                        }}
                                    >
                                        ({recovery.wallet?.display})
                                    </span>
                                {/if}
                            </span>
                            <LoginProvider
                                ethereum={() =>
                                    continueWithEthereumExtension({
                                        info: recovery,
                                        accountToUse: recovery.managed
                                            ? 'personal'
                                            : undefined,
                                        accountSelected,
                                    })}
                                provider={recovery}
                                hideusername
                                prefix="Continue with"
                            />
                        </div>
                    {/if}
                {:else}
                    <LoginProvider
                        login={true}
                        onclick={() =>
                            continueWithProvider({
                                slug: recovery.slug,
                                body: {
                                    accountToUse: 'personal',
                                    login_hint: recovery.login_hint,
                                    accountSelected,
                                },
                                server:
                                    recovery.slug === 'mastodon'
                                        ? recovery.mastodonServer
                                        : null,
                            })}
                        provider={recovery}
                        prefix="Continue with"
                    />
                {/if}
            {/each}
        </div>
    </section>
{/if}
