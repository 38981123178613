<script>
    import { _ } from 'svelte-i18n'
    import { onMount } from 'svelte'
    import { putAuthority } from '$utils/api.js'
    import { getDisplay } from '$utils/common.js'
    import { updateAccounts } from '$utils/profile.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { location } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import PhoneIcon from '$lib/icon/PhoneIcon.svelte'
    import CloseIcon from '$lib/icon/CloseIcon.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'

    onMount(() => {
        notification.clear()
    })

    let upgradeAjax = $state(false)
    async function upgrade() {
        upgradeAjax = true
        const { id, slug, user_name } = global.upgradePreferred.upgrade

        const res = await putAuthority({ preferred: id })

        //End of Wiz Upgrade Funnel
        const isInWizard = !global.data?.actions?.doneWizardAt //this flag is sent only when user completes wizard
        const contact_slug = global.upgradePreferred?.preferred?.slug
        const email_domain =
            global.upgradePreferred?.preferred?.user_name?.split('@')[1]
        //wizard upgrade funnel state is not already sent + is in wizard + is wallet app
        if (
            sessionStorage.wiz_upgrade_funnel === 'wiz_upgrade_prompt' &&
            isInWizard &&
            window.isWalletApp()
        ) {
            logPlausibleEvent({
                n: 'Wiz Upgrade Success',
                p: { contact_slug, email_domain, upgrade_slug: slug },
                u: $location,
            })
            sessionStorage.removeItem('wiz_upgrade_funnel')
        }

        notification.show(
            $_('{provider} {label} is now preferred', {
                values: {
                    provider: getDisplay(slug),
                    label: user_name || '',
                },
            }),
            'success',
        )
        if (Array.isArray(global.data?.profile?.accounts)) {
            global.data.profile.accounts = res.accounts
            global.data = updateAccounts(global.data) //add sortrank, sortlabel and unverified accounts to accounts array
        }
        global.upgradePreferred = null
    }
</script>

<FullPageModal
    close={() => (global.upgradePreferred = null)}
    dataTest="upgrade-preferred-modal"
>
    <button
        aria-label="Close"
        onclick={() => (global.upgradePreferred = null)}
        class="group absolute right-6 top-6"
    >
        <CloseIcon />
    </button>
    <h1 class="font-medium">
        {#if global.upgradePreferred?.linkedSubjects}
            {@html $_(
                'You linked a social provider<br/>associated with your<br/>current preferred login provider',
            )}
        {:else}
            {$_('You linked a social provider')}
        {/if}
    </h1>
    <p class="my-4 w-3/4 text-sm italic opacity-80">
        {#if global.upgradePreferred?.preferred.slug === 'email'}
            {$_(
                'Logging in with a social provider is generally more secure than email',
            )}
        {:else}
            {$_(
                'Logging in with a social provider is generally more secure than phone',
            )}
        {/if}
    </p>

    <h2 class="block w-full text-left font-medium">{$_('Do you want to:')}</h2>

    <!-- svelte-ignore a11y_autofocus -->
    <button
        autofocus
        data-test="change-preferred-btn"
        onclick={upgrade}
        disabled={upgradeAjax}
        class="btn-background mt-3 flex h-24 w-full flex-col {upgradeAjax
            ? 'items-center'
            : 'items-start'} justify-center px-3"
    >
        {#if upgradeAjax}
            <SpinnerIcon css="h-5 w-5" />
        {:else}
            <span class="font-medium"
                >{$_('Change preferred login provider to')}</span
            >
            <div class="mt-2 flex items-center">
                <img
                    class="max-h-[36px] w-9"
                    src="https://cdn.hello.coop/images/{global.upgradePreferred
                        ?.upgrade?.slug}.svg"
                    alt={global.upgradePreferred?.upgrade?.slug}
                />
                <div class="ml-3 flex flex-col items-start">
                    <span
                        >{getDisplay(
                            global.upgradePreferred?.upgrade?.slug,
                        )}</span
                    >
                    <span>{global.upgradePreferred?.upgrade?.user_name}</span>
                </div>
            </div>
        {/if}
    </button>

    <h2 class="my-3 block w-full text-center font-medium uppercase">
        {$_('Or')}
    </h2>

    <button
        data-test="keep-preferred-btn"
        onclick={() => (global.upgradePreferred = null)}
        class="btn-border flex h-24 w-full flex-col items-start justify-center px-3"
    >
        <span class="font-medium">{$_('Keep preferred login provider as')}</span
        >
        <div class="mt-2 flex items-center">
            {#if global.upgradePreferred?.preferred.slug === 'email'}
                <MailIcon size="lg" />
            {:else}
                <PhoneIcon size="lg" />
            {/if}
            <div class="ml-3 flex flex-col items-start">
                <span>{global.upgradePreferred?.preferred?.user_name}</span>
                <span>{$_('with a code')}</span>
            </div>
        </div>
    </button>
</FullPageModal>
